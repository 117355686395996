import {
    HttpErrorResponse,
    HttpHandlerFn,
    HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { environment } from 'environments/environment';
import {
    BehaviorSubject,
    Observable,
    catchError,
    finalize,
    skipWhile,
    switchMap,
    take,
    throwError,
} from 'rxjs';

let isRefreshingToken: boolean = false;
let tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<any> => {
    const authService = inject(AuthService);
    const router = inject(Router);

    // Clone the request object
    let newReq = req.clone();

    // Request
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (
        authService.accessToken &&
        !AuthUtils.isTokenExpired(authService.accessToken)
    ) {
        newReq = req.clone({
            headers: req.headers
                .set('Authorization', `Bearer ${authService.accessToken}`)
                .set(
                    'X-ApplicationThemeId',
                    `${environment.applicationThemeId}`
                ),
        });
    } else {
        newReq = req.clone({
            headers: req.headers.set(
                'X-ApplicationThemeId',
                `${environment.applicationThemeId}`
            ),
        });
    }

    return next(newReq).pipe(
        catchError((error) => {
            if (error instanceof HttpErrorResponse && authService.accessToken) {
                switch (error.status) {
                    case 400:
                        return handle400Error(error, router, authService);
                    case 401:
                        return handle401Error(
                            newReq,
                            next,
                            router,
                            authService
                        );
                    case 403:
                        return handle403Error(error, router);
                    case 404:
                        return handle404Error(error, router);
                }
            }
            return throwError(error);
        })
    );
};

function handle400Error(
    error: HttpErrorResponse,
    router: Router,
    authService: AuthService
): any {
    if (error && error.status === 400 && error.error === 'invalid_grant') {
        // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
        authService.signOut(false);
        navigateToLoginPage(error, router);
        return throwError('Redirected to login page');
    }
    return throwError(error);
}

function handle401Error(
    request: HttpRequest<any>,
    next: HttpHandlerFn,
    router: Router,
    authService: AuthService
) {
    if (!isRefreshingToken) {
        isRefreshingToken = true;

        // Reset here so that the following requests wait until the token
        // comes back from the refreshToken call.
        tokenSubject.next(null);

        // send request for refreshing the tokens
        return authService.doRefreshTokens().pipe(
            switchMap((hasToken: boolean) => {
                if (hasToken) {
                    tokenSubject.next('hasToken');
                    return next(addTokens(request, authService.accessToken));
                }
            }),
            catchError((error) => {
                if (error.status === 403) {
                    handle403Error(error, router);
                } else {
                    // If there is an exception calling 'refreshToken', bad news so logout.
                    authService.signOut(false);
                }

                navigateToLoginPage(error, router);

                return throwError(error);
            }),
            finalize(() => (isRefreshingToken = false))
        );
    } else {
        // the refresh token is in progress, we will wait until tokenSubject has a non-null value
        // - which means the new token is ready and we can rery the request again
        return tokenSubject.pipe(
            skipWhile((value) => !value), // skip null values
            take(1), // I need the value only once
            switchMap(() => next(addTokens(request, authService.accessToken)))
        );
    }
}

function handle403Error(error: any, router: Router) {
    router.navigate(['/error/403']);
    return throwError(error);
}

function navigateToLoginPage(error: any, router: Router) {
    router.navigate(['/sign-in']);
}

function addTokens(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
        setHeaders: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    });
}

function handle404Error(error: any, router: Router) {
    router.navigate(['/error/404']);
    return throwError(error);
}
