import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { MfaAuthGuard } from './core/auth/guards/mfaAuth.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'transactions' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'transactions',
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.routes'
                    ),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.routes'
                    ),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.routes'),
            },
        ],
    },

    // MFA Routes
    {
        path: '',
        canActivate: [MfaAuthGuard],
        canActivateChild: [MfaAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'mfa',
                loadChildren: () =>
                    import('app/modules/multifactor/multifactor.routes'),
            },
        ],
    },

    // Session Routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.routes'
                    ),
            },
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.routes'),
            },
        ],
    },

    // Authenticated User's routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            {
                path: 'landing',
                loadChildren: () =>
                    import('app/modules/landing-page/landing.routes'),
            },
            {
                path: 'home',
                loadChildren: () => import('app/modules/home/home.routes'),
            },
            {
                path: 'payments',
                loadChildren: () =>
                    import('app/modules/payments/payments.routes'),
            },
            {
                path: 'transactions',
                loadChildren: () =>
                    import('app/modules/transactions/transactions.routes'),
            },
            {
                path: 'email-templates',
                loadChildren: () =>
                    import('app/modules/email-template/email-template.routes'),
            },
            {
                path: 'clients',
                loadChildren: () =>
                    import('app/modules/clients/clients.routes'),
            },
            {
                path: 'payees',
                loadChildren: () => import('app/modules/payees/payees.routes'),
            },
            {
                path: 'users',
                loadChildren: () => import('app/modules/users/users.routes'),
            },
            {
                path: 'organisations',
                loadChildren: () =>
                    import('app/modules/organisations/organisations.routes'),
            },
        ],
    },

    // 404 & Catch all
    {
        path: 'page-not-found',
        pathMatch: 'full',
        loadChildren: () => import('app/modules/error/404/error-404.routes'),
    },
    {
        path: 'error',
        children: [
            {
                path: '403',
                loadChildren: () =>
                    import('app/modules/error/403/error-403.routes'),
            },
            {
                path: '500',
                loadChildren: () =>
                    import('app/modules/error/500/error-500.routes'),
            },
        ],
    },
    { path: '**', redirectTo: 'page-not-found' },
];
