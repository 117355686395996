import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private _httpClient = inject(HttpClient);
    private _navigation: ReplaySubject<Navigation> =
        new ReplaySubject<Navigation>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return this._httpClient
            .get<Navigation>(`${environment.apiUrl}/common/navigations`)
            .pipe(
                tap((navigation: any) => {
                    navigation.default = navigation.defaults;
                    navigation.defaults = [];
                    this._navigation.next(navigation);
                })
            );
    }

    /**
     * Get module navigation
     */
    // getNavigation(module: string): Navigation {
    //     let data;
    //     switch (module) {
    //         case 'theming':
    //             data = this._themeNavigation.map((item) => item);
    //             break;
    //     }
    //     const nav = {
    //         default: data,
    //         compact: data,
    //         futuristic: data,
    //         horizontal: data,
    //     };

    //     return nav;
    // }
}
