import { inject } from '@angular/core';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { forkJoin } from 'rxjs';
import { UserService } from './core/user/user.service';
import { AuthService } from './core/auth/auth.service';

export const initialDataResolver = () => {
    const accountService = inject(AuthService);
    const userService = inject(UserService);
    const navigationService = inject(NavigationService);
    //const messagesService = inject(MessagesService);
    // const notificationsService = inject(NotificationsService);
    // const quickChatService = inject(QuickChatService);
    // const shortcutsService = inject(ShortcutsService);

    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
        accountService.loadMyPermissions(),
        userService.get(),
        navigationService.get(),
        // messagesService.getAll(),
        // notificationsService.getAll(),
        // quickChatService.getChats(),
        // shortcutsService.getAll(),
    ]);
};
