export const businesses = [
    {
        abn: '87639995029',
        entityName: '#A PTY LIMITED',
        entityType: 'Australian Private Company',
        address: {
            state: 'NSW',
            postalCode: '2127',
        },
    },
    {
        abn: '11162041929',
        entityName: 'A & CO PTY. LTD',
        entityType: 'Australian Private Company',
        address: {
            state: 'NSW',
            postalCode: '2022',
        },
    },
    {
        abn: '88997973071',
        entityName:
            'D & G RINALDI SUPER FUND & The Trustee for A & TA Hartley Superannuation Fund',
        entityType: 'Other Partnership',
        address: {
            state: 'SA',
            postalCode: '5000',
        },
    },
    {
        abn: '47115584104',
        entityName: 'MD&A PTY LTD',
        entityType: 'Australian Private Company',
        address: {
            state: 'VIC',
            postalCode: '3095',
        },
    },
    {
        abn: '71839814392',
        entityName: 'The Trustee for A & TA Hartley Superannuation Fund',
        entityType: 'Superannuation Fund',
        address: {
            state: 'SA',
            postalCode: '5245',
        },
    },
    {
        abn: '76658547150',
        entityName: 'B TRUCKING PTY LTD',
        entityType: 'Australian Private Company',
        address: {
            state: 'QLD',
            postalCode: '4223',
        },
    },
    {
        abn: '79865350029',
        entityName: 'MEEK, BRANDI YASMIN',
        entityType: 'Individual/Sole Trader',
        address: {
            state: 'NSW',
            postalCode: '2570',
        },
    },
    {
        abn: '67663202962',
        entityName: 'B. ELECTRIC PTY LTD',
        entityType: 'Australian Private Company',
        address: {
            state: 'QLD',
            postalCode: '4213',
        },
    },
    {
        abn: '13162622282',
        entityName: 'CAPITAL B PTY LTD',
        entityType: 'Australian Private Company',
        address: {
            state: 'QLD',
            postalCode: '4000',
        },
    },
    {
        abn: '19168534747',
        entityName: 'FARANTOURIS, NICKOS LUCKY',
        entityType: 'Individual/Sole Trader',
        address: {
            state: 'SA',
            postalCode: '5024',
        },
    },
];
