import { Injectable } from '@angular/core';
import { businesses as businessesData } from './data';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { cloneDeep } from 'lodash';

@Injectable({ providedIn: 'root' })
export class BusinessesMockApi {
    private _businesses: any[] = businessesData;

    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    registerHandlers(): void {
        //-----------------------------------------------------------------------------------------------------
        // @ Businesses - GET
        //-----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onGet('api/common/businesses').reply(() => {
            // Clone the businesses
            const businesses = cloneDeep(this._businesses);

            // Sort the businesses by the name field by default
            businesses.sort(this._sortByEntityName);

            // Return the response
            return [200, businesses];
        });

        //-----------------------------------------------------------------------------------------------------
        // @ Businesses Search - GET
        //-----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/businesses/search')
            .reply(({ request }) => {
                // Get the search query
                const query = request.params.get('query');
                // Clone the businesses
                let businesses = cloneDeep(this._businesses);

                // IF the query exists....
                if (query) {
                    // Filter the contacts
                    businesses = businesses.filter(
                        (business) =>
                            business.entityName &&
                            business.entityName
                                .toLowerCase()
                                .includes(query.toLowerCase())
                    );
                }

                // Sort the contacts by the name field by default
                businesses.sort(this._sortByEntityName);
                return [200, businesses];
            });
    }

    // Sort by entityName
    private _sortByEntityName = (a, b) =>
        a.entityName.localeCompare(b.entityName);
}
